
<template>
  <div>
    <window-header></window-header>
    <div style="position: relative; height:200px; width:370px">
      <field
        name="importe"
        label="Importe"
        widget="float"
        type="number"
        dec="2"
        default="0"
        width="80px"
        style="top:5px; left:10px;"
        readonly
      />
      <field name="fecha" label="Fecha" type="date" width="100px" style="top:5px; left:95px;" />
      <field
        name="generar_cobros"
        label="Generar cobros"
        inline-label
        widget="checkbox"
        style="top:15px; left:220px;"
        default="1"
        @change="onChangeGenerarCobros"
      />
      <field
        ref="nuevoscobros"
        name="nuevoscobros"
        widget="handsontable"
        :height="110"
        :minRows="1"
        style="top:50px; left:5px; width: 375px;"
        :htSettings="nuevoscobrosimpago_customprop"
        :fields="['movimiento','estado']"
        :readonly="!formData.generar_cobros"
        :columns="[
                {name:'tipo',header:'Tipo',type:'select',options:{'letra':'Letra','cheque':'Cheque','transferencia':'Trans. Bancaria','efectivo':'Efectivo'}},
                {name:'fecha',header:'Fecha',type:'date2'},
                {name:'compra_eur',header:'Compra(€)',type:'numeric',numericFormat: { pattern: '0,0.00'}},
              ]"
      ></field>

      <field
        name="gastos"
        label="Gastos financ."
        default="0"
        widget="float"
        type="number"
        dec="2"
        width="80px"
        style="bottom:5px; left:10px;"
        @change="onChangeGastos"
      />
      <field
        name="gastos_porcentaje"
        label="%"
        placeholder="%"
        widget="float"
        type="number"
        dec="2"
        default="0"
        sufix="%"
        width="80px"
        style="bottom:5px; left:95px;"
        @change="updateGastos"
      />
      <button
        class="button"
        style="position:absolute;right:5px;bottom:5px;"
        @click="winOptions.onAccept(self)"
      >Aceptar</button>
    </div>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";

function floatOrCero(v) {
  v = parseFloat(v || 0);
  if (!isFinite(v)) return 0;
  return v;
}
export default {
  mixins: [WindowMixin, rFormMixin],
  props: {
    row: Number,
    importe: Number
  },
  data: function() {
    var self = this;
    return {
      title: "Crear impago",
      mode: "new",
      //dbAdapter: "cliente"
      nuevoscobrosimpago_customprop: {
        cells: function(row, col, prop) {
          var cellProperties = {};
          if (!self.$refs.nuevoscobros.field) return;
          let h = self.$refs.nuevoscobros.field.hotInstance;

          var tipo = h.getSourceData()[row]["tipo"];
          var estado = h.getSourceData()[row]["estado"];
          if (prop == "tipo") {
            cellProperties.renderer = function(
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              switch (value) {
                case "letra":
                  arguments[5] = "Letra";
                  break;
                case "cheque":
                  arguments[5] = "Cheque/Pagaré";
                  break;
                case "transferencia":
                  arguments[5] = "Trans. Bancaria";
                  break;
                case "efectivo":
                  arguments[5] = "Efectivo";
                  break;
              }
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            };
          }
          /*if(prop=='movimiento'){
					cellProperties.renderer = function(instance, td, row, col, prop, value, cellProperties){
                		switch(value){
                			case 'cobro':
                				value = 'Cobro'; break;
                			
                		}
                		Handsontable.TextCell.renderer.apply(this, arguments);
                	};
				}*/
          //ReadOnly
          if (prop == "fecha" || prop == "compra_eur") {
            cellProperties.readOnly = false;
            if (!estado) cellProperties.readOnly = true;
          }

          return cellProperties;
        },
        afterChange: function(changes, source) {
          if (!changes) return;
          if (
            source == "loadData" ||
            source == "load" ||
            source == "modId" ||
            source == "empty"
          )
            return;
          var h = this;
          window.$.each(changes, function(index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            var total =
              parseFloat(self.formData.importe || 0) +
              parseFloat(self.formData.gastos || 0);
            if (prop == "tipo") {
              var estado = null;
              switch (newVal) {
                case "letra":
                  estado = "pendiente_remesar";
                  break;
                case "cheque":
                case "transferencia":
                case "efectivo":
                  estado = "pendiente_cobro";
                  break;
              }
              h.setDataAtRowProp(row, "estado", estado, "auto");
              h.setDataAtRowProp(row, "movimiento", "cobro", "auto");
              if (row == 0)
                h.setDataAtRowProp(row, "compra_eur", total, "auto");
              else h.setDataAtRowProp(row, "compra_eur", 0, "auto");
            }
            if (prop == "compra_eur" && oldVal != newVal && source != "auto") {
              //TODO: si eliminamos la fila
              var acum = 0;
              var n = 0;
              window.$.each(h.getSourceData(), function(i, item) {
                if (item["estado"]) {
                  if (i <= row) {
                    acum += parseFloat(item["compra_eur"]) || 0;
                  }
                  n += 1;
                }
              });
              if (row + 1 == n && row != 0) {
                //Si se edita la última fila
                h.setDataAtRowProp(row, "compra_eur", oldVal, "auto");
                return;
              }
              if (acum > total) {
                h.setDataAtRowProp(row, "compra_eur", oldVal, "auto");
                return;
              }
              var div = (total - acum) / (n - row - 1);
              window.$.each(h.getSourceData(), function(i, item) {
                if (i > row && i < n - 1) {
                  h.setDataAtRowProp(i, "compra_eur", div, "auto");
                } else if (i > row && i == n - 1) {
                  //última fila lo que reste, porque pueden quedar decimales
                  h.setDataAtRowProp(
                    i,
                    "compra_eur",
                    total - acum - (n - row - 2) * div,
                    "auto"
                  );
                }
              });
            }
          });
        }
      }
    };
  },
  methods: {
    updateGastos() {
      var self = this;
      var gastos_porcentaje = parseFloat(self.formData.gastos_porcentaje || 0);
      var importe = parseFloat(self.formData.importe || 0);
      var calc = ((gastos_porcentaje / 100) * importe).toFixed(2);
      if (!isFinite(calc)) calc = 0.0;
      self.$set(self.formData, "gastos", calc);
      self.$nextTick(function() {
        self.onChangeGastos();
      });
    },
    onChangeGastos() {
      var self = this;
      var gastos = parseFloat(self.formData.gastos || 0);
      var importe = parseFloat(self.formData.importe || 0);
      var calc = (100 * (gastos / importe)).toFixed(2);
      //console.log(gastos, importe, calc);
      if (!isFinite(calc)) calc = 0.0;
      self.$set(self.formData, "gastos_porcentaje", calc);

      if (self.formData.generar_cobros) {
        let h = self.$refs.nuevoscobros.field.hotInstance;
        h.setDataAtRowProp(0, "compra_eur", importe + gastos, "edit");
      }
    },
    onChangeGenerarCobros() {
      var self = this;
      if (!self.formData.generar_cobros) {
        self.$set(self.formData, "nuevoscobros", []);
      }
    }
  }
};
</script>